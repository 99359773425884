import React from 'react';
import './CenteredText.css';

const CenteredText = () => {
  return (
    <div className="CenteredText-container">
       <h1 className="Sectionone-heading-text">
          <span className="CenteredText-heading">Meet<br />Our</span> Farmers
        </h1>
      <p className="CenteredText-paragraph">Meet our farmers and see the care that goes into every bag of Healthy Organic Banana Powder.</p>
    </div>
  );
};

export default CenteredText;
