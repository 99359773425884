import React, { useState } from 'react';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import './Header2.css';
import logo from './biofanalogo.png';

const Header2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="second-header">
      {/* Logo */}
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo-img" />
        </Link>
      </div>

      {/* Menu Button */}
      <button className="menu-button" onClick={toggleDropdown}>
        <HiOutlineMenuAlt3 className="menu-icon" />
      </button>

      {/* Navigation Links */}
      <nav className={`menu-items ${isOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/product">Product</Link>
        <Link to="/contact">Contact Us</Link>
      </nav>

      {/* Dropdown Menu for Mobile */}
      {isOpen && (
        <div className="dropdown-menu open">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/product">Product</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header2;
