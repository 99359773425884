import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import './Contactus.css';

const Contactus = () => {
  return (
    <div className="Contact-main-container">
      <div className="Contact-text-container">
        <h1 className="Contact-heading Contact-gradient-text">Contact</h1>
        <p className="Contact-paragraph">
          [Your Company Address Line 1] <br />
          [City, State, ZIP Code] <br />
          [Country]
        </p>
        
        {/* Contact buttons with icons */}
        <div className="Contact-contact-buttons">
          <button className="Contact-call-btn">
            <FaPhoneAlt className="Contact-icon" /> Call Us
          </button>
          <button className="Contact-mail-btn">
            <FaEnvelope className="Contact-icon" /> Email Us
          </button>
          <button className="Contact-whatsapp-btn">
            <FaWhatsapp className="Contact-icon" /> WhatsApp
          </button>
        </div>
      </div>
      <div className="Contact-img-container">
        <img src="/imgcontant.png" alt="Descriptive" className="Contact-image" />
      </div>
    </div>
  );
};

export default Contactus;