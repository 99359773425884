import React, { useState, useEffect } from 'react';
import styles from './Carousel.module.css'; // CSS Module
import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import image4 from './images/image4.jpg';

const Carousel = () => {
  const images = [image1, image2, image3, image4];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className={styles.carousel}>
      <div className={styles['carousel-container']} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div className={styles['carousel-slide']} key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button className={`${styles['carousel-button']} ${styles['carousel-left-button']}`} onClick={prevSlide}>❮</button>
      <button className={`${styles['carousel-button']} ${styles['carousel-right-button']}`} onClick={nextSlide}>❯</button>
      <div className={styles['carousel-indicators']}>
        {images.map((_, index) => (
          <div
            key={index}
            className={`${styles['carousel-indicator']} ${index === currentIndex ? styles['carousel-active'] : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
