// Layout.js
import React from 'react';
import Header from './Components/Header/Header';
import Header2 from './Components/Header2/Header2'; 

const Layout = ({ children }) => {
  return (
    <div>
      <Header/>
      <Header2 />
      {children}
    </div>
  );
};

export default Layout;
