import React from 'react'
import Header from '../Components/Header/Header'
import Header2 from '../Components/Header2/Header2'
import Caruosel from '../Components/Caruosel/Caruosel'
import Contantsection1 from '../Components/Contantsection/Contantsection1'
import Contantsection2 from '../Components/Contantsection/Contantsection2'
import Sales from '../Components/Sales/Sales'
import CenteredText from '../Components/CenteredText/CenteredText'
import FaqContainer from '../Components/FaqContainer/FaqContainer'
import Footer from '../Components/Footer/Footer'

const Home = () => {
  return (
    <div>
      {/* <Header/> */}
      {/* <Header2/> */}
      <Caruosel/> 
      <Contantsection1/>
      <Contantsection2/>
      <CenteredText/>
      
      <Sales/>
      <FaqContainer/>
      <Footer/>
      
      

    </div>
  )
}

export default Home
