import React from 'react';
import './Contantsection2.css'; // External CSS for styling

const Contantsection2 = () => {
  return (
    <div className="container-contant-section-2">
    <div className="Sectiontwo-contant-section-2">
      <div className="Sectiontwo-left-section">
        <h1 className="Sectiontwo-heading">Get Your Banana  <br />Powder Now!</h1>
        <p className="Sectiontwo-paragraph">Boost your nutrition effortlessly—buy Healthy Organic Banana Powder today and feel the difference!</p>
        <button className="Sectiontwo-buy-now-btn">
          Buy Now <span className="Sectiontwo-arrow-icon">→</span>
        </button>
      </div>
      <div className="Sectiontwo-right-section">
        <img src="/banana.png" alt="Basket of Organic Vegetables" className="Sectiontwo-image" />
      </div>
    </div>
    </div>
  );
};

export default Contantsection2;
