import React from 'react';
import './Footer.css';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="footer-main">
    <footer className="footer">
      <div className="footer-section">
      
        <div className="about-us">
        
          <h4 className="footer-h4">ABOUT US</h4>
          <ul className="footer-ul">
            <li className="footer-li"><a className="footer-a" href="#about">About Us</a></li>
           
            
            <li className="footer-li"><a className="footer-a" href="#contact">Contact Us</a></li>
            <li className="footer-li"><a className="footer-a" href="#terms">Terms & Conditions</a></li>
           
          </ul>
        </div>

        <div className="useful-links">
          <h4 className="footer-h4">USEFUL LINKS</h4>
          <ul className="footer-ul">
            <li className="footer-li"><a className="footer-a" href="#privacy">Privacy Policy</a></li>
            <li className="footer-li"><a className="footer-a" href="#faqs">FAQ's</a></li>
           
            
            
          </ul>
        </div>

        <div className="customer-care">
          <h4 className="footer-h4">CUSTOMER CARE</h4>
          <p className="footer-p">Online Order Queries</p>
          <p className="footer-p">Biofanafoods</p>
          <p className="footer-p"><a className="footer-a" href="mailto:">biofanafoods</a></p>
          <p className="footer-p">Timings: 9:00 a.m till 10:00 p.m </p>
          <div className="contact-icons">
            <a href="https://wa.me/9392060676" className="whatsapp"><FaWhatsapp /></a>
            <a href="tel:9392060676" className="phone"><FaPhoneAlt /></a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-icons">
          <a href="https://www.facebook.com"><FaFacebook /></a>
          <a href="https://www.instagram.com"><FaInstagram /></a>
          <a href="https://www.youtube.com"><FaYoutube /></a>
        </div>
      </div>
    </footer>
    </div>
  );
}

export default Footer;
