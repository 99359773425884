// Header.jsx

import React from 'react';
import './Header.css'; // Import your CSS file

const Header = () => {
  return (
    <header className="app-header">
      <h1>Welcome to Biofanafood</h1>
    </header>
  );
}

export default Header;
