import React, { useRef, useState } from 'react'; 
import emailjs from '@emailjs/browser';
import './Sales.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // Importing icons

const Sales = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState('Send Message');

  const sendEmail = (e) => {
    e.preventDefault();

    setButtonText('Sending...');

    emailjs.sendForm(
      'service_2wqywf5',
      'template_opick8a',
      form.current,
      'f5OnMpETeReJc7xFG'
    )
    .then(() => {
      setButtonText('Send Message');
      alert('Email sent successfully!');
    }, (err) => {
      setButtonText('Send Message');
      alert(JSON.stringify(err));
    });

    form.current.reset();
  };

  return (
    <div className="sales-contact-section">
      <div className="sales-contact-info">
        <h2 className="sales-h2">Let's get in touch</h2>
        <p className="sales-p">Contact us for more information about our Healthy Organic Banana Powder!</p>
        <div className="sales-info">
          <p className="sales-p">
            <FaPhone className="sales-icon" />
            <span className="sales-numbers">
              <span>+91 7981982642</span>
              
            </span>
          </p>
          <p className="sales-p">
            <FaEnvelope className="sales-icon" />
            <span className="sales-numbers">
              <span>biofanafoods@gmail.com</span>
             
            </span>
          </p>
          <p className="sales-p">
            <FaMapMarkerAlt className="sales-icon" />
            <span>Pasivedala, Andhra Pradesh</span>
          </p>
        </div>
      </div>
      <div className="sales-form-container">
        <form ref={form} onSubmit={sendEmail}>
          <div className="sales-field">
            <label htmlFor="from_name">Full Name</label>
            <input type="text" name="from_name" id="from_name" required />
          </div>
          <div className="sales-field">
            <label htmlFor="reply_to">Email</label>
            <input type="email" name="reply_to" id="reply_to" required />
          </div>
          <div className="sales-field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" required />
          </div>
          <input type="submit" value={buttonText} className="sales-send-btn" />
        </form>
      </div>
    </div>
  );
};

export default Sales;
