import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './FaqContainer.css'; // Custom CSS for styling

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleAnswer}>
        <h3>{question}</h3>
        <span>{isOpen ? <FaMinus /> : <FaPlus />}</span>
      </div>
      <div
        className={`faq-answer ${isOpen ? 'open' : ''}`}
        style={{
          maxHeight: isOpen ? '300px' : '0', // Adjust based on content size
        }}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
};

const FaqContainer = () => {
  const faqData = [
    {
      question: 'What is BIOFANAFOODS’ Healthy Organic Banana Powder?',
      answer: "BIOFANAFOODS’ Healthy Organic Banana Powder is a premium product made from 100% organic, ripe bananas. It's rich in essential nutrients, making it a versatile ingredient for various recipes.",
    },
    {
      question: 'How is BIOFANAFOODS’ Healthy Organic Banana Powder made?',
      answer: 'Our Healthy Organic Banana Powder is made by carefully selecting ripe bananas, which are then dried and ground into a fine powder to preserve their natural nutrients and flavor.',
    },
    {
        question: 'What are the uses of Healthy Organic Banana Powder?',
        answer: 'Healthy Organic Banana Powder can be used in smoothies, baking, oatmeal, pancakes, and as a natural sweetener in various recipes, enhancing both taste and nutrition.',
      },
      {
        question: 'How can I be sure of the quality of your products?',
        answer: 'At BIOFANAFOODS, we prioritize quality by sourcing our bananas directly from local farmers who adhere to sustainable practices and stringent food safety standards.',
      }
      ,
      {
        question: 'Where can I purchase BIOFANAFOODS’ Healthy Organic Banana Powder?',
        answer: 'You can purchase our Healthy Organic Banana Powder directly from our website or select retailers that carry our products.',
      }
    // Add more FAQs as needed
  ];

  return (
    <div className="faq-container">
     
      <h1 className="faq-heading">Frequently Asked Questions (FAQ)</h1>

     
      {faqData.map((item, index) => (
        <FaqItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FaqContainer;
