import React from 'react';
import './Contantsection1.css';

const Contantsection1 = () => {
  return (
    <div className="Sectionone-main-container">
      <div className="Sectionone-text-container">
        <h1 className="Sectionone-heading">
          <span className="Sectionone-gradient-text">Healthy</span> Organic  <br />Banana Powder
        </h1>
        <p className="Sectionone-paragraph">
        Healthy Organic Banana Powder is made from 100% organic bananas,<br />  rich in essential nutrients. It’s versatile, gluten-free, and perfect for smoothies, baking, and more.
        </p>
        
        {/* Key Points with Tick Marks */}
        <ul className="Sectionone-key-points">
          <li className="Sectionone-list-item">High-quality organic products</li>
          <li className="Sectionone-list-item">Environmentally friendly farming practices</li>
          <li className="Sectionone-list-item">100% natural ingredients</li>
          <li className="Sectionone-list-item">Supporting local farmers</li>
        </ul>

        {/* Buy Now Button */}
        <button className="Sectionone-buy-now-btn">Buy Now</button>
      </div>
      <div className="Sectionone-img-container">
        <img src="/imgcontant.png" alt="Descriptive" className="Sectionone-image" />
      </div>
    </div>
  );
};

export default Contantsection1;
